.ErrorPage {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ErrorPage > header > h1 {
	font-family: Arial, Helvetica, sans-serif;
	font-size: calc(1.2rem + 2vmin);
}

.ErrorPage > p {
	max-width: 32rem;
}
