.Header {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	color: white;
	z-index: 23;
}

.Header > nav {
	display: flex;
	flex: 1;
	height: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.Header > nav a {
	text-decoration: none;
	color: inherit;
	font-size: 1.2rem;
}

.Header > nav a:hover {
	color: white;
}

.Header > nav > a + a {
	margin-left: 1rem;
}

.Header > nav > a:first-child {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.25rem;
}

.Header > nav > a > div.logo {
	width: 2.5rem;
	height: 2.5rem;
	background-image: url('../assets/images/area23logo.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.Header > nav > a > p.logoText {
	flex: 1;
	font-family: 'Squada One', Arial, Helvetica, sans-serif;
	margin-left: 0.25rem;
	font-size: 2.3rem;
}
