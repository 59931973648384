.Footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
}

.Footer nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.Footer a {
	text-decoration: none;
	color: inherit;
	font-size: 1rem;
}

.Footer a:hover {
	color: white;
}

.Footer a + a {
	margin-left: 1.5rem;
}

.Footer p {
	font-size: 0.8rem;
}