body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
}

* {
	box-sizing: border-box;
	font-family: Arial, Helvetica, sans-serif;
}
