.AppRoot {
	position: relative;
	min-height: 100vh;
}

.AppRoot > header {
	position: sticky;
	top: 0;
	width: 100%;
	height: 3rem;
}

.AppRoot > main {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - 3rem);
	padding: 4vmin 0 calc(6rem + 4vmin) 0;
}

.AppRoot > main > div {
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
	align-items: center;
}

.AppRoot > footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 6rem;
	padding-top: 1.5rem;
}
