.Home {
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Home > header {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Home > header > h1 {
	margin: 0;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
	font-size: 1.6em;
}

.Home > section {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 32em;
	max-width: 95vw;
}

.Home > section.intro {
	margin-top: 1.5rem;
}

.Home > section.intro > header > h1 {
	font-size: 1.8em;
	margin: 0;
}

.Home > section.intro > p {
	text-align: left;
	margin: 1rem 0 0 0;
}

.Home > div.logo {
	width: 25vmin;
	height: 25vmin;
	background-image: url('../assets/images/area23logo.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}